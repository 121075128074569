'use client';
import InititaliseAndUpdateFavoritesStore from '@/features/initialise-and-update-favorites-store/InitialiseAndUpdateFavoritesStore';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Template = ({ children }: any) => {
  return (
    <QueryClientProvider client={queryClient}>
      <InititaliseAndUpdateFavoritesStore />
      {children}
    </QueryClientProvider>
  );
};

export default Template;
