'use client';

import { getFavoriteProducts } from '@/shared/api/getFavorites';
import { useFavoritesStore } from '@/shared/store/favoritesStore';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export default function InititaliseAndUpdateFavoritesStore() {
  const setFavoriteProducts = useFavoritesStore(
    (state) => state.setFavoriteProducts
  );

  const { data } = useQuery({
    queryKey: ['favorites'],
    queryFn: () => {
      const res = getFavoriteProducts();
      return res;
    },
  });

  useEffect(() => {
    if (data?.status === 'success') {
      const products = data.data;
      const ids = products.map(
        (product) => product.product.id
      );
      setFavoriteProducts(ids);
    }
  }, [data]);

  return null;
}
