import { API } from '../api';
import { axiosInstance } from '../axios';
import { IFavoritesData } from './types';

export const getFavoriteProducts = async () => {
  try {
    const result =
      await axiosInstance.get<IFavoritesData>(
        API.getFavorites,
        {
          cache: { ttl: 3000 },
        }
      );
    return result.data;
  } catch (e: any) {
    console.error(e);
  }
};
