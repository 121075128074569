import axios, {
  InternalAxiosRequestConfig,
} from 'axios';
import { baseAxiosHeaders } from './prepareHeaders';
import { setupCache } from 'axios-cache-interceptor';

const API_URL =
  process.env.NEXT_PUBLIC_FRONT_API_URL;
const PROXY_API_URL =
  process.env.NEXT_PUBLIC_FRONT_PROXY_API_URL;
const CONTENT_PROXY_API_URL =
  process.env
    .NEXT_PUBLIC_FRONT_CONTENT_PROXY_API_URL;

const a = axios.create({
  headers: baseAxiosHeaders,
  withCredentials: true,
  baseURL:
    typeof window === 'undefined'
      ? API_URL
      : PROXY_API_URL,
});

export const axiosInstance = setupCache(a, {
  ttl: 1000,
});

export const axiosContent = axios.create({
  headers: baseAxiosHeaders,
  withCredentials: true,
  baseURL:
    typeof window === 'undefined'
      ? API_URL
      : CONTENT_PROXY_API_URL,
});

const authRequestInterceptor = (
  config: InternalAxiosRequestConfig
) => {
  const token =
    typeof window !== 'undefined'
      ? localStorage.getItem('token')
      : null;

  if (token) {
    config.headers['Authorization-Token'] = token;
  }

  return config;
};

axiosInstance.interceptors.request.use(
  authRequestInterceptor
);
axiosContent.interceptors.request.use(
  authRequestInterceptor
);
