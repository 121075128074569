import { create } from 'zustand';

interface FavoritesStoreState {
  favoriteProducts: number[] | null;
}

interface FavoritesStoreActions {
  setFavoriteProducts: (state: number[]) => void;
  addFavoriteProduct: (id: number) => void;
  deleteFavoriteProduct: (id: number) => void;
}

export const useFavoritesStore = create<
  FavoritesStoreState & FavoritesStoreActions
>((set, get) => ({
  favoriteProducts: null,
  setFavoriteProducts: (
    productsIds: number[]
  ) => {
    set(() => {
      return {
        favoriteProducts: productsIds,
      };
    });
  },
  addFavoriteProduct: (id: number) => {
    set(() => {
      const favProds =
        get().favoriteProducts || [];
      return {
        favoriteProducts: [...favProds, id],
      };
    });
  },

  deleteFavoriteProduct: (id: number) => {
    set(() => {
      const favProds =
        get().favoriteProducts || [];

      const newFavProds = favProds.filter(
        (prodId) => prodId !== id
      );

      return {
        favoriteProducts: newFavProds,
      };
    });
  },
}));
